
import React from "react"

const Footer = ({ children }) => (
    <footer class="footer has-background-black">
        <div class="content has-text-centered has-text-link-light">
            <p>
                © {new Date().getFullYear()}, Alojamiento gracias a
                {` `}
                <a id="provider" href="https://puntohost.com/web-hosting/?utm_source=sponsor&utm_medium=Link&utm_campaign=Mejorenlaradio">PuntoHost</a>
            </p>
        </div>
    </footer>
)

export default Footer
